<template>
  <div>
    <div>
      <div class="page-head-css">
        <div style="padding-top: 10px;display: flex">

          <span style="display: flex;justify-content: center;flex: 1">
                <a-badge status="success"/>启动
                <a-badge status="error" style="margin-left: 10px"/>关闭
            <a-badge status="default" style="margin-left: 10px" />
                离线
          </span>

        </div>
      </div>
      <div style="margin: 0px 20px">
        <div style="margin-bottom: 8px"></div>
        <a-row :gutter="20">
          <a-col :span="spanNum" v-for="item in pickControlData" :key="item.id">
            <a-card
                hoverable
                class="card-css"
                :style="{
                borderTop:
                  item.status === 'START'
                    ? '4px solid #52c41a'
                    : item.status === 'STOP'
                    ? '4px solid #D9001B'
                    : '4px solid #909399',
              }"
            >
              <div style="display: flex;justify-content: space-between">
                <div style="margin-left: 10px;margin-top: 5px">
                  <img src="../../img/relay/机械.png" style="width: 35px;height: 35px" />
                  <span style="font-size: 14px;color: black">
                  {{item.name}}
                </span>
                </div>
                <div style="margin-right: 10px;margin-top: 5px">
                  <span
                      style="color: #5b5b5b;font-size: 10px;">最后操作时间:{{ item.updateTime | dateFormatFull }}</span>
                </div>
              </div>

              <div>
                <div style="padding: 40px 25px 0px 25px">
                  <a-row>
                    <a-col :span="24">
                      <div style="text-align: center">
                        <div style="height: 30px">
                          <span
                              :style="{
                              fontSize: '20px',
                              fontWeight: '600',
                              color:
                                item.status === 'START'
                                  ? '#52c41a'
                                  : item.status === 'STOP'
                                  ? '#D9001B'
                                  : '#909399',
                            }"
                          >{{ item.status | statusFilter }}</span
                          >
                        </div>
                        <div><span style="color: #373737;font-size: 12px">当前状态</span></div>
                      </div>

                    </a-col>
                  </a-row>
                </div>
              </div>
              <div>
                <div style="padding: 65px 15px 0px 15px">
                  <a-row>
                    <a-col :span="10">
                      <div style="margin-left: 40px">
                        <div style="margin-top: 12px"><span style="font-size: 13px">农场:</span></div>
                        <div style="margin-top: 12px" v-auth="'robotControlData-operation'"><span style="font-size: 13px">操作:</span></div>
                      </div>

                    </a-col>
                    <a-col :span="14">
                      <div>
                        <div style="margin-top: 12px;margin-left: 20px"><span style="color: #373737;font-size: 13px">吉田村</span>
                        </div>
                        <div style="margin-top: 12px;" v-auth="'robotControlData-operation'"><span>
                          <el-button size="mini"
                                     style="background-color: #169BD5;color: #FFFFFF;border: 0px;"
                                     @click="pickStart(item.id)">开始采摘</el-button>
                          <el-button size="mini"
                                     style="background-color: #EC808D;color: #FFFFFF;border: 0px;" @click="pickStop(item.id)">停止采摘</el-button>
                        </span></div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </a-card>
          </a-col>
          <a-col :span="spanNum" v-for="item in machineryControlData" :key="item.id">
            <a-card
                hoverable
                class="card-css"
                :style="{
                borderTop:
                  item.status === 'START'
                    ? '4px solid #52c41a'
                    : (item.status === 'STOP' || item.status === 'PAUSE')
                    ? '4px solid #D9001B'
                    : '4px solid #909399',
              }"
            >
              <div style="display: flex;justify-content: space-between">
                <div style="margin-left: 10px;margin-top: 5px">
                  <img src="../../img/relay/收获机械.png" style="width: 35px;height: 35px" />
                  <span style="font-size: 14px;color: black">
                  {{item.name}}
                </span>
                </div>
                <div style="margin-right: 10px;margin-top: 5px">
                  <span
                      style="color: #5b5b5b;font-size: 10px;">最后操作时间:{{ item.updateTime | dateFormatFull }}</span>
                </div>
              </div>

              <div>
                <div style="padding: 40px 25px 0px 25px">
                  <a-row>
                    <a-col :span="24">
                      <div style="text-align: center">
                        <div style="height: 30px">
                          <span
                              :style="{
                              fontSize: '20px',
                              fontWeight: '600',
                              color:
                                item.status === 'START'
                                  ? '#52c41a'
                                  : (item.status === 'STOP' || item.status === 'PAUSE')
                                  ? '#D9001B'
                                  : '#909399',
                            }"
                          >{{ item.status | mStatusFilter }}</span
                          >
                        </div>
                        <div><span style="color: #373737;font-size: 12px">当前状态</span></div>
                      </div>

                    </a-col>
                    <!--<a-col :span="12">
                      <div style="text-align: center">
                        <div style="height: 30px">
                          <span
                              :style="{
                              fontSize: '20px',
                              fontWeight: '600',
                              color:
                                item.status === 'START'
                                  ? '#52c41a'
                                  : (item.status === 'STOP' || item.status === 'PAUSE')
                                  ? '#D9001B'
                                  : '#909399',
                            }">-</span>
                          <span
                              :style="{
                              fontSize: '10px',
                              fontWeight: '400',
                              color:
                                item.status === 'START'
                                  ? '#52c41a'
                                  : (item.status === 'STOP' || item.status === 'PAUSE')
                                  ? '#D9001B'
                                  : '#909399',
                            }">km/h</span>
                        </div>
                        <div><span style="color: #373737;font-size: 12px">当前时速</span></div>
                      </div>
                    </a-col>-->
                  </a-row>
                </div>
              </div>
              <div>
                <div style="padding: 65px 0px 0px 0px">
                  <a-row>
                    <a-col :span="8">
                      <div style="margin-left: 30px">
                        <div style="height: 30px;margin-top: 5px"><span style="font-size: 12px">选择执行地块:</span></div>
                        <div style="height: 30px;margin-top: 12px" v-auth="'robotControlData-operation'"><span style="font-size: 12px">下发执行指令:</span></div>
                      </div>

                    </a-col>
                    <a-col :span="16">
                      <div>
                        <div style="margin-top: 0px;margin-left: 0px">
                          <span>
                            <a-select
                                v-model = "item.selectValue"
                                placeholder="请选择"
                                allowClear
                                style="width: 120px"
                            >
                  <a-select-option v-for="child in item.farmList" :key="child.farmNum">
                    {{ child.farmName }}
                  </a-select-option>
                </a-select>
                            <el-button size="mini"
                                       style="margin-left: 8px"
                                       @click="machinerySelectPlot(item)">下发</el-button>
                          </span>
                        </div>
                        <div style="margin-top: 12px;" v-auth="'robotControlData-operation'"><span>
                          <el-button size="mini"
                                     style="background-color: #169BD5;color: #FFFFFF;border: 0px;"
                                     @click="machineryOperation(item.id,'START')">启动</el-button>
                          <el-button size="mini"
                                     style="background-color: #EC808D;color: #FFFFFF;border: 0px;" @click="machineryOperation(item.id,'PAUSE')">暂停</el-button>
                          <el-button size="mini"
                                     style="background-color: #EC808D;color: #FFFFFF;border: 0px;" @click="machineryOperation(item.id,'STOP')">停止</el-button>
                        </span></div>
                      </div>
                    </a-col>

                  </a-row>
                  <div style="margin-top: 20px">
                     <span style="font-size: 10px;margin-left: 30px;color: #ff2025">
                     注意:如果农机未选择执行地块 可能启动不成功
                  </span>
                  </div>


                </div>
              </div>
            </a-card>
          </a-col>
         <!-- <a-col :span="spanNum" v-for="item in machineryControlData" :key="item.id">
            <a-card
                hoverable
                class="card-css"
                :style="{borderTop: '4px solid #D9001B'}"
            >
              <div style="display: flex;justify-content: space-between">
                <div style="margin-left: 10px;margin-top: 5px">
                  <img src="../../img/relay/收获机械.png" style="width: 35px;height: 35px" />
                  <span style="font-size: 14px;color: black">
                  {{item.name}}
                </span>
                </div>
                <div style="margin-right: 10px;margin-top: 5px">
                 <span
                     style="color: #5b5b5b;font-size: 10px;">最后操作时间:{{ item.updateTime | dateFormatFull }}</span>
                </div>
              </div>

              <div>
                <div style="padding: 60px 25px 0px 25px">
                  <a-row>
                    <a-col :span="12">
                      <div style="text-align: center">
                        <div style="height: 30px"><span
                            :style="{fontSize: '20px',fontWeight: '600',color:'#D9001B'}">{{item.status  | mStatusFilter}}</span>
                        </div>
                        <div><span style="color: #373737;font-size: 12px">当前状态</span></div>
                      </div>

                    </a-col>
                    <a-col :span="12">
                      <div style="text-align: center">
                        <div style="height: 30px">
                          <span
                              :style="{fontSize: '23px',fontWeight: '600',color:'#D9001B'}">-</span>
                          <span
                              :style="{fontSize: '10px',fontWeight: '400',marginLeft: '3px', color:'#D9001B'}">km/h</span>
                        </div>
                        <div><span style="color: #373737;font-size: 12px">当前时速</span></div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </div>
              <div>
                <div style="padding: 50px 0px 0px 0px">
                  <a-row>
                    <a-col :span="8">
                      <div style="margin-left: 30px">
                        <div style="margin-top: 12px"><span style="font-size: 12px">选择执行地块:</span></div>
                        &lt;!&ndash;<div style="margin-top: 12px"><span style="font-size: 13px">地块:</span></div>&ndash;&gt;
                        <div style="margin-top: 12px" v-auth="'robotControlData-operation'"><span style="font-size: 12px">下发执行指令:</span></div>
                      </div>

                    </a-col>
                    <a-col :span="16">
                      <div>
                        <div style="margin-top: 12px;margin-left: 20px"><span style="color: #373737;font-size: 13px">吉田村第一农村</span>
                        </div>
                        &lt;!&ndash;<div style="margin-top: 12px;margin-left: 20px"><span
                            style="color: #373737;font-size: 13px">B地块</span></div>&ndash;&gt;
                        <div style="margin-top: 12px;" v-auth="'robotControlData-operation'"><span>
                          <el-button size="mini"
                                     style="background-color: #169BD5;color: #FFFFFF;border: 0px;"
                                     @click="nettyStart()">启动</el-button>
                          <el-button size="mini"
                                     style="background-color: #EC808D;color: #FFFFFF;border: 0px;" @click="nettyStop()">停止</el-button>
                          <el-button size="mini"
                                     style="background-color: #EC808D;color: #FFFFFF;border: 0px;" @click="nettyPause()">暂停</el-button>
                        </span></div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </a-card>
          </a-col>-->
        </a-row>

      </div>
    </div>

  </div>
</template>

<script>
    import countTo from 'vue-count-to'

    export default {
        components: {
            countTo,
        },
        data() {
            return {
                timer: "timer",
                pickControlData: [],
                machineryControlData: [],
                screenWidth: document.documentElement.clientWidth,//屏幕宽度
                screenHeight: document.documentElement.clientHeight - 50,//屏幕高度
            }
        },
        computed: {
            spanNum() {
                let spans = ['24', '12', '8', '6', '4', '3'];
                let number = Math.floor(this.screenWidth / 420) - 1;
                if (number < 5) {
                    return spans[number];
                } else if (number == 5) {
                    return spans[3];
                } else if (number == 6 || number == 7) {
                    return spans[4];
                } else if (number > 7) {
                    return spans[5];
                } else {
                    return spans[0];
                }
            },
        },
        mounted() {
            const _this = this;
            window.onresize = function () { // 定义窗口大小变更通知事件
                _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
                _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
            };
            this.timer = setInterval(this.refreshData, 10000);
        },
        filters: {
            statusFilter: function (value) {
                if (!value) return "";
                if (value === "START") {
                    return "正在采摘";
                } else if (value === "STOP") {
                    return "停止";
                } else if (value === "OFFLINE") {
                    return "离线";
                } else {
                    return "";
                }
            },
            mStatusFilter: function (value) {
                if (!value) return "";
                if (value === "START") {
                    return "工作中";
                } else if (value === "STOP") {
                    return "停止";
                } else if (value === "OFFLINE") {
                    return "离线";
                } else if (value === "PAUSE") {
                    return "暂停";
                } else {
                    return "";
                }
            },
        },
        created() {
            this.getPickControlData();
            this.getMachineryControlData();
        },

        beforeDestroy() {
            clearInterval(this.timer);
            this.timer = null;
        },
        methods: {
            machineryOperation(id,status){
                this.http.netty.machineryOperation({
                    id:id,
                    status:status
                }).then((response) => {
                    if (response.data.status == 200) {
                        this.$message.success(response.data.msg);
                    } else {
                        this.$message.warning(response.data.msg);
                    }
                    this.refreshData();
                });
            },
            machinerySelectPlot(item){
                if(item.selectValue&&item.selectValue!=undefined){
                    this.http.netty.machinerySelectPlot({
                        id:item.id,
                        num:item.selectValue
                    }).then((response) => {
                        if (response.data.status == 200) {
                            this.$message.success(response.data.msg);
                        } else {
                            this.$message.warning(response.data.msg);
                        }
                        this.refreshData();
                    });
                }else {
                    this.$message.warning("请选择地块");
                }

            },
            refreshData(){
                this.getPickControlData();
                this.getMachineryControlData();
            },
            async getPickControlData() {
                const {data: res} = await this.http.netty.getPickList();
                console.log(res);
                if (res.status !== 200) {
                    this.$message.error(res.msg);
                }
                this.pickControlData = res.data;
                console.log(this.pickControlData);
            },
            pickStop(id) {
                this.http.netty.pickStop({
                    id:id
                }).then(res =>{
                    if (res.data.status == 200) {
                        this.$notification.open({
                            message: '操作提示',
                            description:
                                res.data.msg,
                            icon: <a-icon type="check-circle" style="color: #52c41a" />,
                    });
                    } else {
                        this.$notification.open({
                            message: '操作提示',
                            description:
                            res.data.msg,
                            icon: <a-icon type="close-circle" style="color: #D9001B" />,
                    });
                    }
                });
            },
            pickStart(id) {
                this.http.netty.pickStart({
                    id:id
                }).then(res =>{
                    if (res.data.status == 200) {
                        this.$notification.open({
                            message: '操作提示',
                            description:
                            res.data.msg,
                            icon: <a-icon type="check-circle" style="color: #52c41a" />,
                    });
                    } else {
                        this.$notification.open({
                            message: '操作提示',
                            description:
                            res.data.msg,
                            icon: <a-icon type="close-circle" style="color: #D9001B" />,
                    });
                    }
                });
            },
            async getMachineryControlData() {
                const {data: res} = await this.http.netty.getMachineryList();
                console.log(res);
                if (res.status !== 200) {
                    this.$message.error(res.msg);
                }
                this.machineryControlData = res.data;
            },

            /*async nettyStart(name) {
                const {data: res} = await this.http.netty.nettyStart();
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.getRelayControlData();
                this.$notification.open({
                    message: '操作提示',
                    description:
                        '打开'+'成功',
                    icon: <a-icon type="check-circle" style="color: #52c41a" />,
            });
            },
            async nettyStop(name) {
                const {data: res} = await this.http.netty.nettyStop();
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.getRelayControlData();
                this.$notification.open({
                    message: '操作提示',
                    description:
                        '打开'+'成功',
                    icon: <a-icon type="check-circle" style="color: #52c41a" />,
            });
            },
            async nettyPause(name) {
                const {data: res} = await this.http.netty.nettyPause();
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.getRelayControlData();
                this.$notification.open({
                    message: '操作提示',
                    description:
                        '打开'+'成功',
                    icon: <a-icon type="check-circle" style="color: #52c41a" />,
            });
            },*/




        },
    }
</script>

<style scoped>

  .card-css >>> .ant-card-body {
    padding: 0px;
  }

  .card-css {
    width: 100%;
    height: 21rem;
    margin: 10px 0px
  }

  /*.crad-detail {
    text-align: center;
    padding: 0 0px 20px 0px;
  }

  .card-css >>> .ant-card-body {
    padding: initial;
    width: 100%;
    height: 180px;
  }

  .real-data-card {
    margin: 20px;
    border: 1px solid #EBEEF5;
    color: #303133;
    background-color: #FFF;
    box-shadow: 0 1px 1px rgb(0 0 0 / 15%);
    border-radius: 2px;
  }*/
</style>
